.password-reset-container {
  // text-align: center;
  width: auto;
  margin: 0 auto;
  // padding: 10px 20px;
  // height: 73vh;
  // height: calc(100vh - 193px);
  display: grid;
  place-content: center;
  img {
    margin: 0 auto;
  }
  .t-s {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    p {
      line-height: 0;
      color: blue;
      span {
        color: #ffffff;
        font-size: 12px;
        height: 30px;
        background: #3d69b2;
        padding: 7px;
        border-radius: 5px;
      }
    }
  }
  p {
    margin: 0;
  }
  p:nth-child(2) {
    font-size: 24px;
    font-weight: 500;
    color: #3d69b2;
  }

  p:nth-child(4) {
    font-size: 14px;
  }

  .otp-boxes {
    input {
      width: 40px !important;
      height: 40px;
    }
  }

  .reset-password {
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    // min-height: 73vh;
    // @media screen and (max-width: 600px) {
    //   min-height: 64vh;
    // }
  }
}
