@import "../../sass/variables";
@import "../../sass/mixins";

.feedback-container {
  padding: 20px;

  .textarea {
    width: 60%;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
  h1 {
    font: 600 26px $font-regular;
  }
  p {
    font: 400 14px $font-regular;
    color: #aaa;
    padding: 20px 0;
    // border-bottom: 1px solid #ccc;
  }
  .feedback-rating {
    .MuiRating-root {
      font-size: 40px;
      margin: 6px 0;
      .MuiRating-label {
        top: 6px;
      }
    }
  }
  .rating-text {
    font: 500 20px $font-regular;
    color: #000;
    margin: 20px 0;
  }
  .feedback-type {
    div {
      display: inline-block;
      svg:last-child {
        top: 8px !important;
      }
    }
    label {
      width: 215px;
      // margin: 10px;
    }
  }
  .feedback-btn {
    display: block;
    // @include submitButton;
    width: 25%;
    color: #fff;
  }
  .custom-error {
    @include validations;
  }
}
