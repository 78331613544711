@import "../../sass/variables";
@import "../../sass/mixins";

.adddocument {
  .dialog-box {
    background: white;
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0 0 9px #e8eaf5;
  }

  .image-title {
    width: 100%;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    padding: 15px 0 15px 0;
    color: var(--tal_primary);
  }

  .close-image-popup {
    position: absolute;
    right: 0;
    margin: 10px 15px;

    border-radius: 50px;
    padding: 4px 5px;
    top: 0;
    box-shadow: 0 0 9px #e8eaf5;

    .MuiIconButton-root {
      padding: 0;
    }
  }

  .add-image-container {
    text-align: center;
    padding: 20px;

    @media screen and (max-width: 960px) {
      margin-top: 58px;
    }

    .dropzone-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 30%;
    }

    .part-1,
    .part-2,
    .part-3 {
      padding: 10px;
      position: relative;
      border-radius: 4px;
      min-height: 250px;
      background-color: #fafafa;
      margin: 10px;
    }

    .part-2,
    .part-3 {
      img {
        vertical-align: middle;
        border-style: none;
        max-width: 200px;
        // max-height: 400px;
        border: 1px solid grey;
      }
    }

    h4 {
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      border-bottom: 1px solid #e7e7e7;
      padding: 10px;
      color: #535353;
    }

    .image {
      div {
        text-align: center;

        svg {
          font-size: 84px;
          color: #000;
        }
      }
    }

    .cropper {
      img {
        width: 100%;
      }
    }

    .preview {
      img {
        width: 100%;
      }

      button {
        width: 100%;
        background: var(--tal_primary);
        color: #fff;
        border-radius: 5px;
      }
    }
  }

  .selected-image-container {
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;

    .selected-image-child {
      // max-width: 400px;
      // max-height: 100%;
      display: inline-block;
      box-shadow: 0 0 3px #00000014;
      margin-right: 10px;
      padding: 10px;
      margin-bottom: 10px;

      img {
        max-width: 200px;
        // max-height: 400px;
        display: flex;
        margin: 0 auto;
      }

      h4 {
        font: 400 12px $font-regular;
        background: green;
        padding: 10px;
        color: #fff;
      }

      .profile-btn {
        background: var(--tal_primary);
        color: #fff;
        padding: 10px 15px;
        //   margin: 1px 3px;
      }

      .del-btn {
        background: #fff;
        color: var(--tal_primary);
        border: 1px solid #ff3a3a;
        padding: 10px;
        border-radius: 5px;
        margin-top: 10px;
      }
    }
  }
}