.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  line-height: 25px;
  height: 62px;
  width: -webkit-fill-available;
  outline: none;
}
.phonecls .react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  line-height: 25px;
  height: 60px;
  width: -webkit-fill-available;
  outline: none;
}
.phonecls .react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #fff;
  border: none;
  line-height: 20px;
 
 
}
.phonecls .react-tel-input{
  border-left: 1px solid #ccc !important;
}
.signphone {
  padding-right: 6px;
  position: relative;
}
.signphone .react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  line-height: 25px;
  height: 45px;
  width: -webkit-fill-available;
  outline: none;
}
.signphone .react-tel-input .form-control:focus {
  box-shadow: none;
}
.signphone .react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #fff;
  border: none;
}
.signphone .react-tel-input {
  border: 1px solid #ccc !important;
  border-radius: 5px;
}
.signphone.phheight .react-tel-input .form-control{
  height: 60px;
}