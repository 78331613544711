@import "../../../sass/variables";
@import "../../../sass/mixins";

.change-password-container {
  width: 29%;
  text-align: center;
  margin: 0 auto !important;
  padding: 60px;
  .headername {
    font-family: $font-regular;
    font-size: 26px;
    margin: 15px 0;
  }
  .login-input {
    @include input-fields;
  }
  .showpassword {
    position: absolute;
    right: 10px;
    top: 18px;
  }
  .invalid {
    color: red;
    line-height: 21px;
    padding-left: 22px;
  }

  .valid {
    color: green;
    line-height: 21px;
    padding-left: 22px;
  }

  .password-strength {
    text-align: start;
    font-size: 12px;
    font-family: $font-regular;
    & h4 {
      margin: 0;
    }
    & ul {
      padding: 0 14px;
      margin: 4px;
      & li {
        padding: 0;
        line-height: 15px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .change-password-container {
    width: 100% !important;
  }
}
