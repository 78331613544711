.profileinfo .MuiFormHelperText-root {
  border-bottom: none !important;
}
.profileinfo .MuiInput-underline:before {
  border-bottom: none !important;
}

.amenities .MuiInput-underline:before {
  border-bottom: none !important;
}
.indianaddress .MuiAutocomplete-endAdornment {
  display: flex;
  top: calc(50% - 25px);
  right: 30px;
}

.indianaddress .MuiInput-underline:before {
  border-bottom: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  z-index: 1 !important;
}

.MuiStepIcon-completed {
  color: rgb(36, 197, 36) !important;
}

@media screen and (max-width: 480px) {
  .areapad {
    padding: 4px;
  }
  .max99 {
    max-width: 99%;
  }
}
.btn_vist {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 5px 15px !important;
}
.ck-modify .ck.ck-content.ck-blurred,
.ck-modify .ck.ck-content.ck-focused {
  height: 150px;
}
.ck-modify .ck.ck-content.ck-blurred p,
.ck-modify .ck.ck-content.ck-focused p {
  margin-bottom: 0;
}
.herobg-black {
  background: rgba(0, 0, 0, 0.3);
  height: 540px;
  margin-top: -20px;
  position: absolute;
  width: 100%;
  z-index: 0;
  display: none;
}
.sortcls {
  padding: 24px 24px 0;
}
.sortcbr {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.sortslct {
  text-align: right;
}
.sortslct select {
  background: #f3f3fa;
  border: 1px solid #b9b9b9;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.MuiInput-underline:after {
  border-bottom: none !important;
}
.getapp {
  margin-top: 80px;
}
@media screen and (max-width: 1024px) {
  .getapp {
    margin-top: 40px;
  }
  .downloadapp {
    padding: 32px !important;
  }
}
@media screen and (max-width: 375px) {
  .getapp ~ a {
    margin-top: 10px;
    display: inline-block;
  }
  .makeStyles-logo-2 {
    width: 210px !important;
    margin-top: 10px;
  }
}
.editBox {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.editUser {
  position: relative;
  text-align: center;
}
.share-pop{justify-content: space-between;}

.share-pop > div{
  padding: 20px;
  text-align: center;
}

.share-pop > div div:first-child{
  margin-bottom: 15px;
}
.share-pop > div div svg{
  width: 25px;
  height: 25px;
}
.share-pop input{
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 15px;
}
.share-pop button{
  width: 100%;
  background:"#3f51b5" !important;
  color: #fff;
  padding:15px 0;
}
.share-pop button:hover{
  width: 100%;
  background:"#3f51b5"!important;
  color: #fff;
  padding:15px 0;
}
.copy-url{position: relative;}
.url-copy{ position: absolute; right: -110px; bottom:-10px; color: #ec1c24;z-index: 99;}
@media screen and (max-width:480px) {
  .share-pop{justify-content: flex-start;}
  .share-pop > div{
    padding: 12px;
  }
  .url-copy{ width:85%; right: unset; bottom: -90px;  text-align: center;}
}
.fund-share-link {
  text-align: left!important;
  background: #f6f6f6;
  padding: 15px;
  border-radius: 5px;
}

.fund-share-link>div {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}
.hyper_txt 
  a:hover{
    color: #000 !important;
    text-decoration:none !important;
  }