.red_text {
  color: #ec1c24;
}
.red_bg {
  background-color: #ec1c24 !important;
}
.txt_white {
  color: #fff !important;
}
.top_menu {
  ul {
    li {
      a:hover {
        background: #ec1c24 !important;
      }
    }
  }
}
