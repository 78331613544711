@import "../../../sass/variables";
@import "../../../sass/mixins";

.password-reset-container {
  margin: 0 auto;
  .password-reset {
    width: 420px;
    padding: 20px;
    .headername {
      font-family: $font-regular;
      font-size: 26px;
      text-align: center;
    }
    .resetpassword-input {
      @include input-fields;
      padding: 20px 0 10px;
    }
  }
}

.forgot-reset-password {
  width: 100%;
  margin: 0 auto;
}

.otp-container {
  min-width: 280px;
  margin: 0 auto;
  // padding: 36px;
  // height: 518px;
}
.otp-boxes {
  div {
    justify-content: center;
    margin: 10px 0;
    div {
      input {
        width: 40px !important;
        height: 40px;
      }
    }
  }
}

.timerOTP {
  display: flex;
  justify-content: space-between;
  max-width: 260px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .password-reset-container {
    width: 80% !important;
    @media screen and (max-width: 400px) {
      padding: 30px;
    }
  }
}

@media screen and (max-width: 600px) {
  .password-reset-container {
    width: 100% !important;
  }
  .otp-container {
    width: 100%;
    padding: 30px 10px;
  }
}

.invalid {
  color: red;
  line-height: 21px;
  padding-left: 22px;
}

.valid {
  color: green;
  line-height: 21px;
  padding-left: 22px;
}

.password-strength {
  text-align: start;
  font-size: 12px;
  font-family: $font-regular;
  & h4 {
    margin: 0;
  }
  & ul {
    padding: 0 14px;
    margin: 4px;
    & li {
      padding: 0;
      line-height: 15px;
    }
  }
}
