@import "../../../sass/mixins";
@import "../../../sass/variables";

.close-btn {
  position: absolute !important;
  right: 0;
}
.signup .signup-input.email {
  max-width: 99%;
}
.login-modal {
  height: 664px;
  overflow-y: scroll;
}
// .right {
//   padding: 0 20px;
// }
.login-title {
  padding: 10px 0 20px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  line-height: 25px;
  height: 35px;
  width: -webkit-fill-available;
  outline: none;
}

.signup {
  width: 100% !important;
  overflow-x: hidden;
  margin: 0 auto;
  height: auto;
  text-align: left;
  // padding: 15px;
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 4px;

  // & .textfield-container {
  //   margin: 15px 0 0 0;
  // }
  label {
    background: #fff;
    padding: 0;
    margin: 0;
    // transform: translate(14px, 14px) scale(1);
  }
  & .signup-input {
    @include input-fields;
    input:not(.PhoneInputInput) {
      height: 30px;
      border-radius: 3px;
      border: 1px solid #ccc !important;
      padding-left: 15px;
      color: #616161;
    }
  }
  & .noReq {
    .MuiInput-underline::before,
    .MuiInput-underline:hover:not(.Mui-disabled)::before,
    .MuiInput-underline::after {
      border-bottom-color: transparent;
    }
  }
  .PhoneInput {
    border: 1px solid #ccc !important;
    border-bottom-width: 2px !important;
    border-radius: 5px;
    width: 99% !important;
    &:hover {
      ::before {
        border-bottom-color: #f44336 !important;
      }
    }
    .PhoneInputCountryIcon {
      margin-left: 15px;
      box-shadow: none;
      background: transparent;
    }
    .PhoneInputCountrySelectArrow {
      margin: 0px 5px 0px 7px;
      opacity: 1;
      font-size: 20px;
    }
    .PhoneInputInput {
      color: #616161;
      font-size: 1em;
      height: 38px;
      &:focus {
        outline: none;
      }
    }
  }
  .customer {
    width: 100%;
    margin: 0px;
    padding: 0px;
    border-radius: 5px;
    position: relative;
    .label {
      position: absolute;
      z-index: 5;
      top: -13px;
      left: 10px;
      background: white;
      padding: 5px;
      border-radius: 0;
    }
  }
  .flname {
    width: 100%;
    & .names {
      display: inline-block;
      max-width: 48%;
      @media screen and (max-width: 480px) {
        max-width: 100%;
      }
      &:first-child {
        margin-right: 14px;
      }
      // &:last-child {
      //   //margin-left: 14px;
      //   width: 50%;
      // }
    }
  }
  .pwd {
    width: 100%;
    .pass-container {
      display: inline-grid;
      width: 100%;
      .pass-field {
        width: 99%;
      }
    }
  }
  .headername {
    text-align: left;
    & h2 {
      color: #373737;
      font: 600 20px $font-regular;
      margin: 0px;
      .close-login {
        float: right;
        cursor: pointer;
        padding-right: 25px;
      }
    }
    & p {
      font-family: $font-regular;
      font-size: 16px;
      margin: 4px 0;
    }
  }
  .grid_spend {
    display: flex;
    justify-content: space-between;
    label {
      margin-right: 0 !important;
    }
  }
  .border-radius {
    width: 100%;
    margin: 10px 0;
    input {
      border: none;
      width: 100%;
      box-shadow: none;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    &.telephone {
      & .iti--allow-dropdown {
        width: 100%;
        & ul {
          padding: 15px;
        }
      }
      & input {
        padding: 12px;
        padding-left: 50px !important;
      }
    }
  }

  .flag-dropdown {
    border: none;
  }

  .custom-error {
    @include validations;
  }

  .valid {
    color: green;
  }

  .invalid {
    color: #cd2627;
  }

  .error-text {
    div {
      border-bottom: 1px solid #cd2627;
    }
  }

  .password-strength {
    text-align: start;
    font-size: 12px;
    font-family: $font-roboto;
    & h4 {
      margin: 0;
    }
    & ul {
      padding: 0 14px;
      margin: 4px;
      list-style: none;
      & li {
        padding: 0;
        line-height: 15px;
      }
    }
  }

  .terms {
    font-size: 12px;
    font-family: $font-roboto;
    font-weight: 400;
    line-height: 30px;
    margin: 8px 0;
    @media screen and (max-width: 960px) {
      text-align: center;
    }
    a{
      color: #ec1c24;
      text-decoration: none;
    }
    span {
      padding: 0px;
      &.termsLink {
        color: "primary";
      }
    }
  }

  .register-btn {
    .signup-btn {
      background: "primary";
      width: 100%;
      font-size: 17px;
      font-weight: 600;
    }
  }

  .sub-header-name {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin: 10px 0px;
    font-family: $font-roboto;

    .forgot-password {
      .onHover {
        color: "#007bff";
        font-weight: 400;
        padding-left: 10px;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      a{
        color: #ec1c24;
      }
    }
  }
  .hr-line {
    width: 100%;
    margin: 25px auto 0px auto;
    font-size: 16px;
    text-align: center;
    span {
      width: 100%;
      &::before {
        content: " ";
        border-bottom: 1px solid #ccc;
        width: 25%;
        display: inline-block;
        position: relative;
        bottom: 5px;
        margin: 0px 10px 0px 0px;
        @media screen and (max-width: 600px) {
          display: block;
          width: 100% !important;
          margin: 0;
        }
      }
      &::after {
        content: " ";
        border-bottom: 1px solid #ccc;
        width: 25%;
        display: inline-block;
        position: relative;
        bottom: 5px;
        margin: 0px 0px 0px 15px;
        @media screen and (max-width: 600px) {
          display: block;
          width: 100% !important;
          margin: 0;
          padding-top: 15px;
        }
      }
    }
  }
  .social-logins {
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    ul {
      margin: 8px auto;
      padding: 0px;
      width: 100%;
      li {
        display: inline-block;
        width: auto;
        margin: 10px;
        padding: 10px 30px 0px;
        box-shadow: 0 0 13px #f4f5f8;
        background-color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
        img {
          // height: 30px;
          vertical-align: baseline;
        }
        &:hover {
          box-shadow: 0 0 10px #cacaca;
        }
      }
    }
  }
  .footerText {
    font-family: $font-roboto;
    text-align: center;
    margin: 45px 0px 0px 0px;
    p {
      margin: 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  .signup.container {
    width: 54%;
    height: 420px;
  }
}

@media screen and (max-width: 600px) {
  .signup.container {
    width: 64%;
    height: 420px;
    overflow-y: scroll;
  }
  .names {
    width: 100% !important;
    &:first-child {
      margin-right: 0 !important;
    }
    &:last-child {
      margin-left: 0 !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .login-container {
    .login-title {
      margin: 0;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      position: sticky;
      top: 0;
      background: white;
      z-index: 2;
      span {
        font-size: 1.2rem;
      }
    }
    .left {
      display: none;
    }
    .right {
      width: 100%;
    }

    .right .social-logins ul {
      margin: 30px 0 0px 0;
      padding: 0px;
      width: 100%;
      li {
        width: 19%;
        @media screen and (max-width: 400px) {
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  }
}
