@import "../../../sass/variables";
@import "../../../sass/mixins";

.login-modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.login-modal .MuiBackdrop-root {
  background: transparent;
  width: 35%;
  margin: 0 auto;
}

.login-container {
  margin: 0 auto;
  width: 480px;
  padding: 30px;
  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 0 10px;
  }

  .login-title {
    margin: 0;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: #ec1c24;

    span {
      font-weight: bold;
    }
  }
  .left {
    margin-right: 20px;
  }
  .right {
    .signIn-box {
      font-family: $font-roboto;

      padding: 20px 0 0 0;
      background: #fff;

      border-radius: 4px;

      text-align: left;
      outline: none;

      @media screen and (max-width: 600px) {
        margin: 10px;
      }

      .loginCont-title {
        font-weight: 600;
        font-size: 20px;
        margin: 0px 0px 20px 0px;
      }
      .headername {
        h4 {
          font-size: 26px;
          font-weight: 900;
          font-family: $font-regular;
          margin: 6px 0;
          margin-left: 24px;
          .close-login {
            float: right;
            cursor: pointer;
          }
        }
        p {
          font-size: 16px;
          font-weight: 500;
          font-family: $font-roboto;
          margin: 6px 0;
        }
      }

      .login-input {
        margin: 10px 0;
        // @include input-fields;
        input {
          height: 46px !important;
          border: 1px solid #ccc !important;
          border-bottom-width: 2px !important;
          background-color: #fff !important;
          color: #616161;
        }
      }

      .error-text {
        div {
          border-bottom: 1px solid #cd2627;
        }
      }

      .validation-error {
        @include validations;
      }

      .showpassword {
        position: absolute;
        right: 0;
        top: 28px;
        & button {
          margin: 0;
          &:hover {
            background: transparent;
          }
          & svg {
            width: 0.7em;
          }
        }
      }

      .remembr-me {
        width: 100%;
        margin: 0 auto;
        & .row3 {
          padding: 15px 0;
          font-size: 16px;
          &:first-child {
            text-align: start;
            @media screen and (max-width: 600px) {
              text-align: center;
            }
          }

          &:last-child {
            text-align: end;
            text-decoration: underline;
            @media screen and (max-width: 600px) {
              text-align: center;
            }
          }
          #remember-me {
            margin-right: 5px;
          }
          span.onHover {
            color: "primary";
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .submit-button {
        width: 100%;
        margin: 10px 0;
        height: 56px;
        font-size: 17px;
        font-weight: 600;
        span {
          color: #fff;
        }
        // &:hover {
        //   //background: $primary-button;
        // }
      }

      .social-logins {
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        ul {
          margin: 10px 0;
          padding: 0px;
          width: 100%;
          li {
            display: inline-block;
            width: auto;
            margin: 10px;
            padding: 10px 30px 0px;
            box-shadow: 0 0 13px #f4f5f8;
            background-color: #ffffff;
            border-radius: 4px;
            cursor: pointer;
            &:hover {
              background: rgb(245, 247, 251);
            }
            img {
              vertical-align: baseline;
              // height: 30px;
            }
          }
        }
      }

      .noAccount {
        margin: 10px 0;
        font-size: 16px;
        text-align: center;
        & span {
          color: "primary";
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        a{
          color: #ec1c24;
        }
      }
      .hr-line {
        width: 100%;
        margin: 25px auto 0px auto;
        font-size: 16px;
        text-align: center;
        span {
          width: 100%;

          &::before {
            content: " ";
            border-bottom: 1px solid #ccc;
            width: 25%;
            display: inline-block;
            position: relative;
            bottom: 5px;
            margin: 0px 10px 0px 0px;
            @media screen and (max-width: 600px) {
              display: block;
              width: 100% !important;
              margin: 0;
            }
          }
          &::after {
            content: " ";
            border-bottom: 1px solid #ccc;
            width: 25%;
            display: inline-block;
            position: relative;
            bottom: 5px;
            margin: 0px 0px 0px 15px;
            @media screen and (max-width: 600px) {
              display: block;
              width: 100% !important;
              margin: 0;
              padding-top: 15px;
            }
          }
        }
        & hr {
          width: 22%;
          display: inline-block;
          margin-bottom: 4px;
          border: 1px solid #e8e8e8;
        }
      }
      .footerText {
        font-family: $font-roboto;
        text-align: center;
        margin: 45px 0px 0px 0px;
        p {
          margin: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .login-container {
    .left {
      display: none;
    }
    .right {
      width: 100%;
    }
    // .hr-line span::before {
    //   width: 90px !important;
    // }
    // .hr-line span::after {
    //   width: 102px !important;
    // }
    .right .signIn-box .social-logins ul {
      margin: 10px 0;
      padding: 0px;
      width: 100%;
      li {
        width: 19%;
        @media screen and (max-width: 400px) {
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  }
}
