@import "../../sass/variables";
@import "../../sass/mixins";

.prop-menu {
  margin: 1rem 0;
  padding: 0;
  grid-gap: 30px;
  display: flex;
}
.prop-menu li {
  list-style-type: none;
  margin-bottom: -3px;
}
.prop-menu a {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 15px;
  border-bottom: 2px solid #f8f8f8;
  color: #000;
}
.prop-menu a:hover {
  color: #ec1c24;
  border-bottom: 2px solid #ec1c24;
  text-decoration: none;
}

.inter-links {
  position: relative;
  span {
    position: absolute;
    top: -160px;
  }
}

.login-modal {
  background-color: rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 960px) {
  .btn-margin {
    margin-bottom: 20px;
  }
}

.login-modal .MuiBackdrop-root {
  background: transparent;
  width: 35%;
}
.App-navigation {
  margin-top: 20px;
  font-weight: 700;
  h3 {
    font-size: 24px;
  }
}
.image-gallery-slides {
  background-color: #f8f8f8;
}

.developer-container {
  padding: 0 20px;
  // max-width: 1170px;

  @media screen and (max-width: 1170px) {
    padding: 0 10px;
  }

  .login-title {
    margin: 0px -20px 20px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    background: #f8f8f8;

    span {
      font-weight: bold;
    }
  }
  .left {
    margin-right: 20px;
  }
  .right {
    .signIn-box {
      background: #fff;

      border-radius: 4px;

      text-align: left;
      outline: none;

      // @media screen and (max-width: 600px) {
      //   margin: 10px;
      // }

      .loginCont-title {
        font-weight: 600;
        font-size: 20px;
        margin: 0px 0px 20px 0px;
      }
      .headername {
        h4 {
          font-size: 26px;
          font-weight: 900;
          font-family: $font-regular;
          margin: 6px 0;
          margin-left: 24px;
          .close-login {
            float: right;
            cursor: pointer;
          }
        }
        p {
          font-size: 16px;
          font-weight: 500;
          font-family: $font-roboto;
          margin: 6px 0;
        }
      }

      .login-input {
        margin: 10px 0;
        // @include input-fields;
        input {
          height: 46px !important;
          border: 1px solid #ccc !important;
          border-bottom-width: 2px !important;
          background-color: #fff !important;
          color: #616161;
        }
      }

      .error-text {
        div {
          border-bottom: 1px solid #cd2627;
        }
      }

      .validation-error {
        @include validations;
      }

      .showpassword {
        position: absolute;
        right: 0;
        top: 28px;
        & button {
          margin: 0;
          &:hover {
            background: transparent;
          }
          & svg {
            width: 0.7em;
          }
        }
      }

      .remembr-me {
        width: 100%;
        & .row3 {
          padding: 15px 0;
          font-size: 16px;
          &:first-child {
            text-align: start;
            @media screen and (max-width: 600px) {
              text-align: center;
            }
          }

          &:last-child {
            text-align: end;
            text-decoration: underline;
            @media screen and (max-width: 600px) {
              text-align: center;
            }
          }
          #remember-me {
            margin-right: 5px;
          }
          span.onHover {
            color: "primary";
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .submit-button {
        width: 100%;
        margin: 10px 0;
        // height: 56px;
        font-size: 14px !important;
        font-weight: 600;
        span {
          color: #fff;
        }
        // &:hover {
        //   //background: $primary-button;
        // }
      }

      .noAccount {
        margin: 10px 0;
        font-size: 16px;
        text-align: center;
        & span {
          color: "primary";
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .hr-line {
        width: 100%;
        font-size: 16px;
        text-align: center;
        span {
          width: 100%;

          &::before {
            content: " ";
            border-bottom: 1px solid #ccc;
            width: 25%;
            display: inline-block;
            position: relative;
            bottom: 5px;
            margin: 0px 10px 0px 0px;
            @media screen and (max-width: 600px) {
              display: block;
              width: 100% !important;
              margin: 0;
            }
          }
          &::after {
            content: " ";
            border-bottom: 1px solid #ccc;
            width: 25%;
            display: inline-block;
            position: relative;
            bottom: 5px;
            margin: 0px 0px 0px 15px;
            @media screen and (max-width: 600px) {
              display: block;
              width: 100% !important;
              margin: 0;
              padding-top: 15px;
            }
          }
        }
        & hr {
          width: 22%;
          display: inline-block;
          margin-bottom: 4px;
          border: 1px solid #e8e8e8;
        }
      }
      .footerText {
        font-family: $font-roboto;
        text-align: center;
        margin: 45px 0px 0px 0px;
        p {
          margin: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .developer-container {
    .left {
      display: none;
    }
    .right {
      width: 100%;
    }
    // .hr-line span::before {
    //   width: 90px !important;
    // }
    // .hr-line span::after {
    //   width: 102px !important;
    // }
    .right .signIn-box .social-logins ul {
      margin: 10px 0;
      padding: 0px;
      width: 100%;
      li {
        width: 19%;
        @media screen and (max-width: 400px) {
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  }
}

.builderinfo {
  padding: 0 20px;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }
  span {
    color: #aaa;
    font-weight: normal !important;
    font-size: 13px;
  }
  h6:first-child {
    font-weight: bold;
  }
  h6:last-child {
    font-weight: normal !important;
    font-size: 14px;
    color: #426cb4;
  }
}
.close-btn svg {
  width: 1.6em;
  height: 1.6em;
}
.txtCenter {
  text-align: center;
  @media screen and (max-width: 768px) {
    text-align: left;
  }
}
.pl8 {
  padding-left: 8px;
  @media screen and (max-width: 768px) {
    padding-left: 0px;
  }
}
@media screen and (min-width: 1024px) {
  .cntral {
    text-align: center;
    padding-left: 0 !important;
  }
}
.prop_head{text-transform: capitalize;color: #ec1c24;}
.main-title{font-size: 24px;font-weight: 700;}
