.PropertyCard {
  padding: 10px 0;
  background-color: white;
}
.gallery {
  position: relative;
}

.gallery span {
  color: black !important;
  font-size: 18px;
}

.gallery span:nth-child(2) {
  color: #717171;
}

.card {
  text-align: center;
  height: 100vh;
  margin: 10px;
  border: 1px solid #ececec !important;
  box-shadow: none;
  max-height: 420px;
  overflow: auto !important;
  min-height: 400px;
}

.card button {
  background-color: #f0f0f0;
  padding: 0;
}
.menu-wrapper--inner {
  display: flex;
  white-space: normal !important;
}
.card_img {
  background-color: #fcfcfc;
  display: grid;
  place-content: center;
  max-height: 150px;
  min-height: 150px;
}
.card_text {
  background-color: white;
  min-height: 160px;
  max-height: 100vh;
  padding: 15px;
  /*overflow-y: auto;*/
}
.card .card_img > img {
  max-width: 100%;
  max-height: 150px;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.propertyInfo {
  text-align: left;
  /* white-space: break-spaces; */
}

/* .menu-item-wrapper.active {
    border: 1px blue solid;
  } */
.menu-item.active {
  border: 1px green solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}
.arrow-prev,
.arrow-next {
  background-color: #dadaee;
  font-size: 40px;
  padding: 10px;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: grid;
  place-content: center;
}
.arrow-prev:hover,
.arrow-next:hover {
  background-color: #1c3988;
}
.gallery .menu-item-wrapper .card div span{
  text-align: left;
  float: left;
  font-size: 16px;
  color: rgb(113,113,113) !important;
}