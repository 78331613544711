@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');


body {
  margin: 0;
  padding: 0 !important;
  overflow-x: hidden;
  overflow-y: auto !important;
  color: #000;
  font-family: Manrope;
  background-color: white !important;
  font-family: roboto !important;
}

h1,
h5 {
  font-family: Merriweather !important;
  font-weight: bold !important;
}

/* p {
  font-family: Manrope !important;
} */

.toast {
  max-width: 80% !important;
  margin: 0 auto;
}

.toast-message {
  color: #fff;
}

.toast-success {
  background-color: #51a351 !important;
}
.toast-error {
  background-color: #bd362f !important;
}
.toast-info {
  background-color: #2f96b4 !important;
}
.toast-warning {
  background-color: #f89406 !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.input-field.edit-username {
  margin: 5px 0 15px !important;
}

.edit-username div {
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  height: 40px;
}

.edit-username div input {
  padding: 10px 12px 10px;
}

.edit-username div:before {
  border-bottom: none;
}

div[role="tooltip"] {
  z-index: 0 !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.region-change .MuiMenu-paper {
  top: 60px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f53b3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f53b3;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.no-req-text {
  text-align: center;
  padding: 20px 0;
  margin: 10px ;
  font: 600 20px;
  font-style: italic;
}

.report-modal p span {
  font: 500 12px;
  background: #eee;
  border-radius: 25px;
  padding: 8px 10px;
  margin: 3px;
  display: inline-block;
  cursor: pointer;
}

.report-modal:focus {
  border: none !important;
  outline: none !important;
}

.PhoneInputCountryIcon {
  background: transparent !important;
  box-shadow: none !important;
  height: auto !important;
  border-left: none !important;
}

button:focus {
  border: none;
  outline: none !important;
}

/* button:hover {
  background: transparent !important;
} */

button .MuiTouchRipple-root {
  display: none !important;
}

.PhoneInputCountrySelectArrow {
  border-left: none !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ccc;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #ccc;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #ccc;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #ccc;
}

.tooltip-dots {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: red;
  border-radius: 50%;
  margin-right: 8px;
}

.my-profile-cntnr,
.my-avatar-cntnr {
  position: inherit !important;
  top: 40px !important;
}

.my-avatar-cntnr div {
  width: 120px;
}

.my-profile-cntnr div,
.my-avatar-cntnr div {
  top: 85px !important;
  z-index: 2;
}

.my-profile-not-selected,
.my-avatar-not-selected {
  display: contents;
}

.my-profile-not-selected div,
.my-avatar-not-selected div {
  top: 165px !important;
  z-index: 2;
}

div[role="tooltip"] .MuiTooltip-arrow-26 {
  color: #fff !important;
}

.MuiStepLabel-label {
  margin-top: 4px !important;
}

.upload-cntnr .MuiDialog-paper {
  width: 85% !important;
  height: 85% !important;
  border-radius: 4px !important;
}

.share-modal {
  background-image: url("/images/invite-email.png");
  background-position: right bottom;
  background-size: 35%;
  background-repeat: no-repeat;
  position: relative;
}

.share-modal .share-close {
  float: right;
  position: absolute;
  right: -27px;
  font-size: 26px;
  color: #fff;
  background: #999;
  border-radius: 50%;
  top: 2px;
  cursor: pointer;
}

.share-modal:focus {
  border: none !important;
  outline: none !important;
}

.share-modal h5 {
  font: 400 16px;
  margin-bottom: 25px;
  background: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
}

.share-modal h1 {
  font: 700 22px "Roboto";
}

.share-modal p {
  font: 400 18px;
  padding: 20px 0;
}

.share-modal label {
  font: 500 18px;
}

.share-modal .req-link {
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 3px;
}

.share-modal .req-link div:first-child {
  width: 80%;
  display: inline-block;
}

.share-modal .req-link div input {
  width: 100%;
  border: none;
}

.share-btn button,
.share-btn a {
  background: transparent;
  border: 1px solid #6d6d6d;
  color: #6d6d6d;
  width: 100px;
  border-radius: 4px;
  padding: 4px;
  margin: 0 10px;
}

.share-modal .req-link div button {
  background: #fc6923 !important;
  width: 100%;
  margin: 10px 0;
  font-size: 14px;
  color: #fff !important;
  font-weight: 600;
  border: none;
  padding: 6px;
  border-radius: 4px;
}

.share-btn a {
  text-align: center;
  display: inline-block;
}

.share-modal .req-link div:last-child {
  width: 20%;
  display: inline-block;
}

.MuiIconButton-root:hover {
  background-color: transparent;
}

.edit-number {
  width: 250px;
  /* text-align: end; */
  text-decoration: underline;
  color: cadetblue;
}
