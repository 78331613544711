.barnav {
  background-color: white;
  padding: 10px;
  // margin-bottom: 10px;
  margin-top: 10px;
  position: sticky;
  top: 70px;
  border-bottom: 5px solid rgb(246, 247, 251);
  z-index: 3;

  .DayPickerKeyboardShortcuts_show {
    border: none !important;
  }
  .DateRangePickerInput__withBorder {
    border-radius: 4px !important;
    border: 1px solid #cbcbcb !important;
  }
  .appbar-icon {
    margin-top: 10px;
  }
  .DateRangePickerInput_calendarIcon {
    border: none !important;
    margin: 0;
  }
  .MuiInputLabel-shrink {
    transform: translate(12px, -4px) scale(0.75) !important;
  }
  @media screen and (max-width: 960px) {
    top: 89px;
    position: fixed;
    width: 100%;
  }
  h4 {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #ec1c24;
    font-size: calc(12px + 1vw);
    padding: 0;
  }

  .search-right {
    display: flex;
    justify-content: space-between;
  }
}

.barnav button:nth-child(1) {
  color: #ef6c00;
  min-width: 50px;
  height: 50px;
  border: 2px solid #ef6c00;
  border-radius: 100%;
  margin-right: 10px;
}

.bar-main {
  display: flex;
  margin-bottom: 5px;
  padding: 6px;
  justify-content: space-between;
  position: sticky;
  top: 165px;
  // width: 100%;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  .result {
    margin: 0;
    margin-top: 8px;
    @media screen and (max-width: 600px) {
      padding: 10px 0;
      margin: 0;
    }
  }
}
