.tab-box {
  position: absolute;
  background: #fff;
  z-index: 9;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 195px;
  top: 72px;
  float: right;
  box-shadow: 0 2px 4px #3a3a3a;
  span {
    right: -5px;
  }
  p {
    text-align: left;
  }
}
.logore {
  width: 35%;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}
